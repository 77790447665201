import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container">
                <div className="o-flex o-flex--third">
                    {data.contentfulPhotoGallery.photos.map((photo, index) =>
                        <div key={index}>
                            <img className="u-border" alt={photo.title} src={photo.resize.src} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
    contentfulPhotoGallery(website: {title: {eq: "Black Ice"}}) {
        photos {
            title
            resize(width: 600, height: 400) {
                src
            }
        }
    }
}
`;
